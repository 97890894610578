import BN from 'bn.js'

import { CertificateUtils } from './CertificateUtils'
import './cadesplugin_api'

let canAsync = true

const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256 = 101
const CAPICOM_CURRENT_USER_STORE = 2
const CAPICOM_MY_STORE = 'My'
const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2
const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0

export const SignUtils = {
    getAllCertificates() {
        return canAsync
            ? CertificateUtils.checkAllCertificatesAsync()
            : CertificateUtils.checkAllCertificatesSync()
    },

    async gostAftSign(selectedCertificate, dataToSign) {
        let oStore = await cadesplugin.CreateObjectAsync('CAdESCOM.Store')
        await oStore.Open(
            CAPICOM_CURRENT_USER_STORE,
            CAPICOM_MY_STORE,
            CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
        )
        let Certificates = await oStore.Certificates
        let oCertificates = await Certificates.Find(
            CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
            selectedCertificate,
        )
        let Count = await oCertificates.Count
        if (Count == 0) {
            throw 'Certificate not found'
        }
        let oCertificate = await oCertificates.Item(1)

        // Создаем объект CAdESCOM.HashedData
        let oHashedData = await cadesplugin.CreateObjectAsync('CAdESCOM.HashedData')

        // Инициализируем объект заранее вычисленным хэш-значением
        // Алгоритм хэширования нужно указать до того, как будет передано хэш-значение
        await oHashedData.propset_Algorithm(CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256)
        const dataForSign = dataToSign.slice(2).toUpperCase()
        await oHashedData.SetHashValue(dataForSign)
        var oPublicKey = await oCertificate.PublicKey()
        const [name, length, data] = await Promise.all([
            Promise.resolve(oPublicKey.Algorithm).then((oid) => oid.FriendlyName),
            oPublicKey.Length,
            Promise.resolve(oPublicKey.EncodedKey)
                .then((encodedKey) =>
                    isString(encodedKey.Value) ? encodedKey.Value : encodedKey.Value(),
                )
                .then(
                    // NOTE base64 is ASN.1 BER sequence with a single octet string value,
                    // but instead of parsing it, we can simply get last 64 bytes.
                    (base64) => base64ToArrayBuffer(base64).slice(-64),
                ),
        ])

        // Создаем объект CAdESCOM.RawSignature
        var oRawSignature = await cadesplugin.CreateObjectAsync('CAdESCOM.RawSignature')

        // Вычисляем значение подписи
        try {
            var sRawSignature = await oRawSignature.SignHash(oHashedData, oCertificate)
        } catch (e) {
            console.error('sRawSignature error: ', e)
        }
        const sig = await hexStringToByte(sRawSignature)
        const r = sig.slice(0, 32)
        const s = sig.slice(32, 64)
        const msgHash = await hexStringToByte(dataForSign)
        try {
            if (arrayBufferEqual(gostRecover(r, s, 0, msgHash), data)) {
                const v = new Buffer.from([0])
                return [arrayBufferToBase64(concatTypedArrays(sig, v)), arrayBufferToBase64(data)]
            }
        } catch (e) {
            console.error('gostRecover error: ', e)
        }

        if (arrayBufferEqual(gostRecover(r, s, 1, msgHash), data)) {
            const v = new Buffer.from([1])
            return [arrayBufferToBase64(concatTypedArrays(sig, v)), arrayBufferToBase64(data)]
        }
        console.error('unable to recover public key')
    },
}

export function arrayBufferEqual(a, b) {
    if (!a || !b) {
        return false
    }

    if (a === b) {
        return true
    }

    if (a.byteLength !== b.byteLength) {
        return false
    }

    // TODO optimize by time using byte alignment.
    const view1 = new DataView(a)
    const view2 = new DataView(b)

    let i = a.byteLength
    while (i--) {
        if (view1.getUint8(i) !== view2.getUint8(i)) {
            return false
        }
    }

    return true
}

/**
 * @license MIT https://github.com/wbinnssmith/arraybuffer-equal
 */

function add(p1x, p1y, p2x, p2y, a, b, p) {
    let t
    if (p1x.cmp(p2x) === 0 && p1y.cmp(p2y) === 0) {
        const tx = p1y.muln(2).invm(p)
        t = p1x.mul(p1x).muln(3).add(a).mul(tx).mod(p)
    } else {
        const tx = p2x.sub(p1x).umod(p)
        const ty = p2y.sub(p1y).umod(p)
        t = tx.invm(p).mul(ty).mod(p)
    }

    const tx = t.mul(t).sub(p1x).sub(p2x).umod(p)
    const ty = p1x.sub(tx).mul(t).sub(p1y).umod(p)

    return [tx, ty]
}

function isOnCurve(x, y, a, b, p) {
    return x.sqr().mul(x).add(x.mul(a)).add(b).mod(p).cmp(y.sqr().mod(p)) === 0
}

function arrayBufferToBase64(buffer) {
    var binary = ''
    var bytes = new Uint8Array(buffer)
    var len = bytes.byteLength
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

function exp(deg, x, y, a, b, p) {
    if (deg.cmpn(0) === 0) {
        throw new Error('bad degree')
    }
    const subDeg = deg.subn(1)

    let tx = x
    let ty = y
    while (subDeg.cmpn(0) !== 0) {
        if (subDeg.testn(0)) {
            // NOTE check if 0'th bit is 1.
            ;[tx, ty] = add(tx, ty, x, y, a, b, p)
        }
        subDeg.iushrn(1) // NOTE in-place unsigned shift right plain number.
        ;[x, y] = add(x, y, x, y, a, b, p)
    }

    return isOnCurve(tx, ty, a, b, p) ? [tx, ty] : [new BN(0), new BN(0)]
}

function scalarMult(x, y, k, a, b, p) {
    return k.cmpn(1) === 0 ? [x, y] : exp(k, x, y, a, b, p)
}

function yFromX(x, odd, a, b, p) {
    const yy = x.sqr().mul(x).add(x.mul(a)).add(b)
    const red = BN.red(p)
    let y = yy.toRed(red).redSqrt().fromRed()
    if (odd !== y.isOdd()) {
        // NOTE y = p - y;
        y = p.sub(y)
    }
    // TODO check y^2 = x^3 + ax + b?
    if (odd !== y.isOdd()) {
        throw new Error("v doesn't match y oddness")
    }
    if (!isOnCurve(x, y, a, b, p)) {
        throw new Error('calculated R is not on curve')
    }

    return y
}

export function gostRecover(r, s, v, msgHash) {
    const rBN = new BN(r, 'le')
    const sBN = new BN(s, 'le')

    // NOTE id-GostR3410-2001-CryptoPro-A-ParamSet
    const a = new BN('fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffd94', 16)
    const b = new BN('a6', 16)
    const p = new BN('fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffd97', 16)
    const n = new BN('ffffffffffffffffffffffffffffffff6c611070995ad10045841b09b761b893', 16)
    const Gx = new BN('01', 16)
    const Gy = new BN('8d91e471e0989cda27df505a453f2b7635294f2ddf23e3b122acc99c9e9f1e14', 16)

    const x = rBN
    if (x.cmp(p) !== -1) {
        throw new Error('calculated x is equal or greater than curve p')
    }

    const y = yFromX(x, v === 1, a, b, p)
    const [nx, ny] = scalarMult(x, y, n, a, b, p)
    if (nx.cmpn(0) !== 0 || ny.cmpn(0) !== 0) {
        throw new Error("n*R doesn't equal the point at infinity")
    }

    const i = rBN.invm(n)
    const S = i.mul(sBN).mod(n)
    const [sx, sy] = scalarMult(Gx, Gy, S, a, b, p)
    const e = new BN(msgHash, 'le').neg().umod(n).mul(i).umod(n)
    const [ex, ey] = scalarMult(x, y, e, a, b, p)
    const [qx, qy] = add(sx, sy, ex, ey, a, b, p)
    if (qx.cmpn(0) === 0 || qy.cmpn(0) === 0) {
        throw new Error('unable to recover public key')
    }

    let result
    try {
        const qxArray = qx.toArray(null, 32)
        const qyArray = qy.toArray(null, 32)
        result = new Uint8Array([...qyArray, ...qxArray]).reverse().buffer
    } catch (e) {
        console.log(e)
    }
    return result
}

export function hexStringToByte(str) {
    if (!str) {
        return new Uint8Array()
    }
    let a = []
    for (let i = 0, len = str.length; i < len; i += 2) {
        a.push(parseInt(str.substr(i, 2), 16))
    }
    return new Uint8Array(a)
}

export function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}

export function isString(str) {
    return typeof str === 'string'
}

export function concatTypedArrays(a, b) {
    var c = new a.constructor(a.length + b.length)
    c.set(a, 0)
    c.set(b, a.length)
    return c
}
